
.outer{
    background-image: url('../../common/assests/bgimg.jpg');
    height: 100vh !important;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: auto;
    /* background-color:#065F46; */

}

.signup-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100vh !important;
    padding: 16px !important;
  }
  
  .signup-box {
    width: 100% !important;
    max-width: 480px !important;
  }
  
  .signup-card-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .signup-logo {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin-top: 10px !important;
  }
  
  .signup-logo-img {
    height: 160px !important;
    width: 143px !important;
  }
  
  .signup-welcome {
    text-align: left !important;
    /* padding-right: 5px !important; */
  }
  
  .signup-welcome-text {
    color: #362B24 !important;
    font-weight: 700 !important;
    margin-top: 25px !important;
    font-size: 24px !important;
    /* margin-left: -20px !important; */
  }
  
  .signup-subtext {
    color: #362B24 !important;
    font-weight: 400 !important;
    margin-top: 15px !important;
    font-size: 16px !important;
    opacity: 0.5 !important;
  }
  
  .signup-input-wrapper {
    width: 90% !important;
    margin-top: 20px !important;
  }
  
  .signup-label {
    margin-bottom: 8px !important;
    text-align: left !important;
    margin-left: 20px !important;
    font-size: 17px !important;
    font-weight: 600 !important;
  }
  
  .signup-icon {
    width: 20px !important;
  }
  
  .signup-password-toggle {
    width: 20px !important;
    cursor: pointer !important;
    opacity: 0.4 !important;
  }
  
  .signup-forgot-password {
    width: 90% !important;
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 10px !important;
  }
  
  .signup-forgot-password-text {
    color: #065F46 !important;
    font-size: 17px !important;
    font-weight: 700 !important;
    cursor: pointer !important;
  }
  
  .signup-button-wrapper {
    padding: 12px !important;
    margin-top: 10px !important;
    width: 92% !important;
    display: flex !important;
    justify-content: center !important;
  }
  