.sidebarTab-eventManagement {
    border-radius: 24px !important;
    background: #fff !important;
    box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.04) !important;
    padding: 16px !important;
    width: 100% !important;
    max-width: 1460px !important;
    margin: auto !important;
    box-sizing: border-box !important;
    overflow: hidden !important;
  }
  
  .sidebarTab-eventManagement-header {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #362b24 !important;
    font-family: Heebo !important;
  }
  
  .sidebarTab-eventCard {
    border-radius: 22px !important;
    background: #fff !important;
    box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important;
    max-width: 1380px !important;
    min-height: 116px !important;
    flex-shrink: 0 !important;
    margin-bottom: 16px !important;
    margin-top: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 16px !important;
    box-sizing: border-box !important;
    flex-direction: column !important;
  }
  
  @media (min-width: 600px) {
    .sidebarTab-eventCard {
      flex-direction: row !important;
    }
  }
  
  .sidebarTab-eventDetails {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    gap: 20px !important;
    width: 100% !important;
  }
  
  @media (min-width: 600px) {
    .sidebarTab-eventDetails {
      flex-direction: row !important;
      gap: 0px !important;
    }
  }
  
  .sidebarTab-eventImage {
    border-radius: 13px !important;
    margin-right: 16px !important;
    width: 91px !important;
    height: 96px !important;
    object-fit: cover !important;
  }
  
  .sidebarTab-eventHeader {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 8px !important;
  }
  
  .sidebarTab-eventContent {
    font-size: 17px !important;
    font-weight: 600 !important;
    color: #362b24 !important;
    font-family: Heebo !important;
  }
  
  .sidebarTab-clockImage {
    margin-left: 12px !important;
    width: 16px !important;
    height: 16px !important;
  }
  
  .sidebarTab-eventDate {
    font-size: 14px !important;
    color: #065f46 !important;
    font-family: Heebo !important;
    margin-left: 5px !important;
  }
  
  .sidebarTab-eventCategory {
    font-size: 14px !important;
    color: #7d6f6f !important;
    font-family: Heebo !important;
    max-width: 300px !important;
    font-weight: 400 !important;
  }
  
  .sidebarTab-eventDescription {
    font-size: 14px !important;
    color: #7d6f6f !important;
    font-family: Heebo !important;
    max-width: 900px !important;
    width: 100% !important;
    opacity: 0.6 !important;
  }
  
  .sidebarTab-actionIcons {
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
  }
  
  .sidebarTab-viewIcon {
    height: 44px !important;
    width: 45px !important;
    margin-top: 3px !important;
  }
  

  