.main-container{
    margin: 80px 48px 0px 366px;
}

.main-container {
    transition: margin-left 0.3s ease;
  }
  
  .sidebar-open {
    margin-left: 240px; 
  }

@media screen and (max-width: 1024px) {

    .main-container{
        /* margin:50px 20px 20px 50px; */
        margin:50px 20px 20px 20px;
    }
}


@media screen and (max-width:767px){
    .main-container{
        margin:50px 20px 20px 20px;
    }
}

@media screen and (max-width:576px){
    .main-container{
        margin:50px 20px 20px 20px;
    }
}

@media screen and (max-width:480px){
    .main-container{
        margin:50px 20px 20px 20px;
    }
}
/* 
@media screen and (max-width:320px){
    .main-container{
        margin:50px 0px 20px 20px;
    }
    
} */