.graph-container-width{
    max-width: 717px;
    width: 100%;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; 
    justify-content: space-between;
  }
  
  /* For screen sizes 1600px and above */
  @media  screen and (min-width: 1600px) {
    .custom-row > .col-12 {
      flex: 1 19.1%; /* 5 cards per row */
    }
  }
  
  /* For screen sizes between 1401px and 1599px */
  @media  screen and (min-width: 1401px) and (max-width: 1599px) {
    .custom-row > .col-12 {
      flex: 1 20%; /* 4 cards per row */
    }
  }
  
  /* For screen sizes between 1200px and 1400px */
  @media  screen and (min-width: 1200px) and (max-width: 1400px) {
    .custom-row > .col-12 {
      flex: 0 0 30%; /* 3 cards per row */
    }
  }
  
  /* For screen sizes between 1024px and 1199px */
  @media  screen and (min-width: 1024px) and (max-width: 1199px) {
    .custom-row > .col-12 {
      flex: 0 0 48%; /* 2 cards per row */
    }
  }
  
  /* For screen sizes 1023px and below */
  @media screen and (max-width: 1023px) {
    .custom-row > .col-12 {
      flex: 0 0  30%; 
    }
  }

  @media  screen and (max-width: 767px) {
    .custom-row > .col-12 {
      flex: 0 0 31%; 
    }
  }

  @media  screen and (max-width: 576px) {
    .custom-row > .col-12 {
      flex: 1 44%; 
    }
  }
  
  @media  screen and (max-width: 480px) {
    .custom-row > .col-12 {
      flex: 1 1 100%; 
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
    }
  }
  

@media screen and (max-width:767px) {
    .dash-card-adjustment{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}
@media screen and (max-width:1599px) {
   .graph-container-width{
        max-width: 540px;
        /* width: 100%; */
        /* width:  */
    }
    
}