.chat-container {
    border-radius: 24px !important;
    background-color: #FFF !important;
    box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.04) !important;
    width: 100% !important;
    max-width: 1460px !important;
    height: 80vh !important;
    flex-shrink: 0 !important;
    margin-top: 40px !important;
    padding: 16px !important;
    box-sizing: border-box !important;
    margin-bottom: 30px !important;
  }
  
  .chat-grid-container {
    height: 100% !important;
    align-items: stretch !important;
  }
  
  .chat-left-column {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }
  
  .chat-left-box {
    height: 100% !important;
    overflow-y: auto !important;
    margin-top: 16px !important;
  }
  
  .chat-search-box {
    display: flex !important;
    justify-content: center !important;
  }
  
  .chat-search-input {
    width: 90% !important;
    height: 52px !important;
    flex-shrink: 0 !important;
    border-radius: 33px !important;
    background: #EBEBEB !important;
    border: none !important;
    margin-bottom: 20px !important;
    outline:none !important;
  }
  
  .search-icon {
    color: #000 !important;
    opacity: 0.35 !important;
  }
  
  .chat-ticket-list {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  
  .chat-ticket-item {
    border-radius: 18px !important;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #FFF !important;
    width: 80% !important;
    height: auto !important;
    flex-shrink: 0 !important;
    margin-bottom: 16px !important;
    padding: 16px !important;
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    cursor: pointer !important;
    opacity: 0.55 !important;
    transition: all 0.3s ease !important;
  }
  
  .active-ticket {
    opacity:1!important;
    box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important;
  }
  .non-active-ticket{
    opacity:  0.6 !important;
    /* box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important; */
  }
  
  .ticket-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }
  
  .ticket-title {
    color: #362B24 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    overflow-wrap: break-word;
  }
  
  .ticket-status {
    border-radius: 28px !important;
    width: 44px !important;
    height: 22px !important;
    flex-shrink: 0 !important;
    color: #FFF !important;
    font-size: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .ticket-open {
    background-color: #EA4335 !important;
  }
  
  .ticket-closed {
    background-color: #43A047 !important;
  }
  
  .ticket-date {
    display: flex !important;
    align-items: center !important;
    margin-top: 5px !important;
    flex-wrap: wrap !important;
  }
  
  .watch-icon {
    width: 20px !important;
    opacity: 0.55 !important;
  }
  
  .date-text {
    opacity: 0.55 !important;
    font-size: 11px !important;
    margin-left: 5px !important;
  }
  
  .ticket-description {
    opacity: 0.55 !important;
    font-size: 11px !important;
    margin-top: 10px !important;
    overflow-wrap: break-word;
  }
  
  .chat-right-column {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: stretch !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 16px !important;
    height: 80vh !important;
  }
  
  .chat-ticket-details {
    margin-bottom: 16px !important;
  }
  
  .ticket-info-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: stretch !important;
    margin-bottom: 16px !important;
    padding: 15px !important;
    flex-wrap: wrap !important;
  }
  
  .ticket-info {
    display: flex !important;
    gap: 10px !important;
  }
  
  .ticket-title-details {
    font-size: 18px !important;
    font-weight: bold !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
  }
  
  .ticket-number {
    font-size: 12px !important;
    color: #7D7D7D !important;
  }
  
  .ticket-status-details {
    border-radius: 20px !important;
    padding: 4px 10px !important;
    font-size: 12px !important;
    color: #FFF !important;
    height: 25px !important;
    width: auto !important;
  }
  
  .ticket-options {
    display: flex !important;
    flex-direction: column !important;
    background-color: #FFF !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2) !important;
    position: absolute !important;
    top: 25px !important;
    right: 15px !important;
    z-index: 10 !important;
    padding: 8px !important;
    border-radius: 10px !important;
  }
  
  .option-item {
    padding: 10px !important;
    cursor: pointer !important;
  }
  
  .chat-messages-container {
    overflow-y: auto !important;
    padding: 16px !important;
    /* height: 800px; */
    /* height: 49vh; */
    height: 69vh;
}


.intersection-line{
    height: 98%;
    border: 1.5px solid #EBEBEB;
}
  
.ticket-reported {
  background-color: rgb(255, 174, 0);  /* Yellow background */
  color: white;              /* Black text */
}
  /* .chat-message {
    display: flex !important;
    margin: 8px 0 !important;
    margin-top: 10px !important;
    margin-bottom: 8px !important;
  } */
  
  /* .message-incoming {
    align-self: flex-start !important; 
    background-color: #EBEBEB !important;
    padding: 10px !important;
    border-radius: 10px !important;
    width: fit-content !important;
    border-radius: 25px 25px 25px 0px !important;
  } */
  
  /* .message-outgoing {
    align-self: flex-end !important;
    color: #323232;
    padding: 10px !important;
    border-radius: 10px !important;
    width: fit-content !important;
    margin-left: auto !important;
   border-radius:  18px 18px 18px 0px !important;
   background: linear-gradient(112deg, #EBEBEB 7.8%, #EBEBEB 78.62%) !important;
   background: linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%) !important;
  } */
  
  .chat-input-section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 16px !important;
    /* border-top: 1px solid #EBEBEB !important; */
    position: relative !important;
    padding: 10px !important;
  }
  
  .emoji-icon {
    font-size: 30px !important;
    opacity: 0.65 !important;
    position: absolute !important;
    left: 25px !important;
    cursor: pointer;
    color: #BDBDBD;
    
  }
  
  .chat-input {
    width: 100% !important;
    border: none !important;
    background: none !important;
    outline: none !important;
    border-radius: 28px !important;
    background: #EBEBEB !important;
    width: 100% !important;
    flex-shrink: 1;
    padding: 0 16px 0 55px;
    height: 50px;
  }
  
  .send-button {
    background: linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%) !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 10px !important;
    height: 50px !important;
    width: 55px !important;
    border-radius: 28px !important;
  }
  
  .send-icon {
    width: 45px !important;
  }
  
  .emoji-picker-container{
    
      position: absolute;
      bottom: 60px; 
      left: 10px;
      z-index: 9999;
    
  }


  /* Drawer Container */
.drawer-container {
    border-radius: 24px !important;
    background-color: #FFF !important;
    box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.04) !important;
    padding: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }
  
  /* Header (Title and Close Button) */
  .drawer-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }
  
  .drawer-title {
    font-size: 17px !important;
    font-weight: 700 !important;
    /* width: 250px; */
  }
  
  .drawer-close-icon {
    height: 28px !important;
    width: 28px !important;
  }
  
  /* More Icon Button */
  .drawer-more-icon {
    margin-top: 8px !important;
    display: flex !important;
    justify-content: flex-end !important;
  }
  
  /* Message Container */
  .drawer-message-container {
    flex-grow: 1 !important;
    overflow-y: auto !important;
    padding: 8px !important;
    border-radius: 8px !important;
    margin-bottom: 16px !important;
    border: 1px solid !important;
    margin-top: 20px !important;

    width: 300px !important;
  }
  
  /* Message Box */
  .drawer-message-box {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
  }
  
  /* Message Bubble Styles */
  .message-bubble {
    border-radius: 18px !important;
    padding: 8px 16px !important;
    max-width: 80% !important;
  }
  
  .outgoing {
    background: linear-gradient(112deg, #07523D 7.8%, #168F6D 78.62%) !important;
    color: white !important;
    align-self: flex-end !important;
  }
  
  .incoming {
    background-color: #EBEBEB !important;
    color: black !important;
    align-self: flex-start !important;
  }
  
  /* Message Input Container */
  .message-input-container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
  }
  
  /* Message Input Box */
  .message-input-box {
    display: flex !important;
    align-items: center !important;
    background: #EBEBEB !important;
    border-radius: 28px !important;
    width: 100% !important;
    height: 56px !important;
    padding-left: 16px !important;
  }
  
  @media (max-width: 600px) {
    .message-input-box {
      height: 48px !important;
      padding-left: 12px !important;
    }
  }
  
  /* Message Input Field */
  .message-input {
    flex: 1 !important;
    border: none !important;
    outline: none !important;
    background: transparent !important;
    padding: 10px 12px !important;
    font-size: 16px !important;
  }
  
  /* Send Icon */
  .send-icon-container {
    cursor: pointer !important;
    margin-left: 10px !important;
    height: 45px !important;
    width: 45px !important;
    border-radius: 28px !important;
    background-color: #065F46 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 2px !important;
  }
  
  .send-icon {
    width: 15px !important;
    height: 15px !important;
  }
  


  /* TicketDetailsModal.css */
  
.ticket-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px !important;
    background-color: #FFF !important;
    width: 90% !important;
    height: auto !important;
    flex-shrink: 0 !important;
    margin: auto !important;
    padding: 16px !important; 
    box-shadow: 24 !important;
    outline: none !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: scroll !important;
  }
  
  /* Responsive styles */
  @media (min-width: 600px) {
    .ticket-modal {
      width: 70% !important; 
    }
   
  }
  
  @media (min-width: 900px) {
    .ticket-modal {
      width: 500px !important; 
      height: 450px !important; 
    }
  }
  
  @media (min-width: 1920px) {
    .ticket-modal {
      width: 500px !important; 
      height: auto !important; 
    }
    .message-time{
        font-size: 14px !important;
    }
  }

  @media (max-width: 600px) {
    .chat-right-column{
        display: none !important;
    }
    .drawer-title {
        width: 250px;
      }
      
   
  }

  @media (max-width: 800px) {
    .ticket-title-details {
        font-size: 14px !important;
    }
   
  }
  @media (max-width: 1024px) {
    .send-button{
        width: 55px!important;
    }
    
   
  }
  @media (max-width: 1200px) {
    .send-button{
        width: 75px!important;
    }
   
  }
  
  
  /* Hide scrollbar */
  .ticket-modal::-webkit-scrollbar {
    display: none !important; 
  }
  
  .ticket-modal {
    scrollbar-width: none !important; 
  }
  