.allvender {
  font-family: "Heebo"!important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #362b24 !important;
}


.vendor-detail-toggle-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }
      
      .vendor-detail-button-box {
        position: relative;
        display: flex;
        align-items: center;
        width: 594px;
        height: 66px;
        border-radius: 34px;
        background: #FFF;
        box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
        font-weight: bold;
        font-family: 'Heebo';
        box-sizing: border-box;
      }
      
      .vendor-detail-toggle-btn {
        padding: 10px 20px;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        /* z-index: 1;/ */
        color: #362B24;
        opacity: 0.4;
        font-family: 'Heebo';
        font-size: 14px;
        width: 33.33%;
      }
      
      #vendor-detail-btn {
        position: absolute;
        height: 54px;
        border-radius: 28px;
        background: #065F46;
        transition: all 0.3s;
        z-index: 0;
        margin-left: 5px;
      }
      
      .vendor-detail-toggle-btn.active {
        color: #FFF;
        opacity: 1;
        font-weight: 700;
      }
      
      /* Responsive adjustments */
      @media (max-width: 480px) {
        .vendor-detail-button-box {
          height: 50px;
          border-radius: 25px;
          width: 286px;
        }
      
        .vendor-detail-toggle-btn {
          padding: 10px 16px;
          font-size: 8px;
        }
      
        #vendor-detail-btn {
          height: 40px;
          width: 34% !important;
          border-radius: 20px;
        }
      }
      
      