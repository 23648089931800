.serch-bar{
/* border: none; */
outline: none;
}

.menu-itm{
    border-radius: 85% I !important;
}
.notification-popup {
    width: 406px;
    max-width: 90%;
    border-radius: 32px;
    background: #FFF;
    padding: 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  
  .notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .notification-logo {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  
  .notification-info {
    display: flex;
    flex-direction: column;
  }
  
  .notification-text {
    font-size: 16px;
    font-weight: 700;
    color: #362B24;
    margin: 0;
  }
  
  .notification-time {
    font-size: 12px;
    color: #999;
    margin: 0;
  }
  
  .notification-description {
    font-size: 14px;
    color: #333;
    margin: 16px 0;
  }
  
  .notification-divider {
    border: none;
    border-top: 1px solid #eee;
    margin: 16px 0;
  }
  
  .notification-footer {
    font-size: 14px;
  }
  
  .notification-new {
    font-size: 16px;
    font-weight: 700;
    color: #362B24;
    margin-bottom: 8px;
  }
  
  .notification-item {
    margin-bottom: 8px;
  }
  
  .notification-item-text {
    font-size: 14px;
    color: #333;
  }
  
.whole-container-list-bellIcon{
  height: 200px;
  overflow-y: scroll;
  font-size: 11px;
  font-family: 'Heebo' !important;
  margin-top: 14px  ;
}
.whole-container-list-bellIcon::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.logo-belIcon{
  height: 35px;
}
.belIcon-list-container{
  display: flex;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.belIcon-text-list{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.time-txt{
  display: flex;
  justify-content: space-between;
}