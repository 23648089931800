.Orders-tbl-cell{
  color: "#362B24" !important;
  font-weight: "700" !important;
  font-family: "Heebo" !important;
  font-size: "20px" !important;
 
}
/* sx={{
    color: "#362B24",
    fontWeight: "600",
    fontFamily: "Heebo",
    fontSize: "16px",
  }} */

  /* OrderProducts.css */

.orderProductsContainer {
  margin-top: 40px;
  margin-bottom: 30px;
}

.orderProductsTableContainer {
  padding: 26px;
  border-radius: 24px;
}

.orderProductsTitle {
  font-weight: 700;
  color: #362b24;
  font-size: 18px;
}

.orderProductsTable {
  min-width: 650px;
  margin-top: 16px; /* Adjusting marginTop to 16px for uniformity */
}

.orderProductsHeaderCell {
  color: #362b24;
  font-weight: 500;
  font-family: Heebo;
  font-size: 16px;
}

.orderProductsImage {
  height: 35px;
  width: 35px;
  margin-right: 8px;
  border-radius: 10px;
}
