.user-info-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .user-info-button-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 788px;
    height: 66px;
    border-radius: 34px;
    background: #FFF;
    box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
    font-weight: bold;
    font-family: 'Heebo';
    box-sizing: border-box;
  }
  
  .user-info-toggle-btn {
    padding: 10px 20px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #362B24;
    opacity: 0.4;
    font-family: 'Heebo';
    font-size: 14px;
    width: 25%;
  }
  
  #user-info-btn {
    position: absolute;
    height: 54px;
    border-radius: 28px;
    background: #065F46;
    transition: all 0.3s;
    z-index: 0;
    margin-left: 5px;
  }
  
  .user-info-toggle-btn.active {
    color: #FFF;
    opacity: 1;
    font-weight: 700;
  }
  
  
  @media (max-width: 480px) {
    .user-info-button-box {
      height: 40px;
      border-radius: 25px;
      width: 413px;
    }
  
    .user-info-toggle-btn {
      padding: 10px 16px;
      font-size: 8px;
    }
  
    #user-info-btn {
      height: 30px;
      width: 22% !important;
      border-radius: 20px;
    }
  }

  @media screen and (max-width:600px) {
    .joined-forum-img{
      width: 100% !important;
      height: 150px !important;
    }
  }
  