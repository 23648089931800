.userprofile-button-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 352px;
    /* width: 100%; */
    /* max-width: 852px; */
    height: 66px;
    border-radius: 34px;
    background: #FFF;
    box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
    font-weight: bold;
    font-family: 'Heebo';
    box-sizing: border-box;
  }
  
  .userprofile-toggle-btn {
    padding: 10px 20px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #362B24;
    opacity: 0.4;
    font-family: 'Heebo';
    font-size: 16px;
    width: 50%;
  }
  
  #userprofile-btn {
    position: absolute;
    height: 54px;
    border-radius: 28px;
    background: #065F46;
    transition: all 0.3s;
    z-index: 0;
    margin-left: 7px;
  }
  
  /* Active state styling for buttons */
  .userprofile-toggle-btn.active {
    color: #FFF;
    opacity: 1;
    font-weight: 700;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .userprofile-button-box {
      height: 50px;
      border-radius: 25px;
      width: 220px;
    }
  
    .userprofile-toggle-btn {
      padding: 10px 16px;
      font-size: 9px;
    }
  
    #userprofile-btn {
      height: 40px;
      width: 44% !important;
      border-radius: 20px;
    }
  }
  



  /* edit profile */
  /* Container Styles */


.edit-profile {
  width: 100%;
  /* padding: 10px; */
}

/* Typography */
.edit-profile-label {
  margin-bottom: 8px !important;
  text-align: left !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: 'Heebo' !important;
  text-indent: 18px !important;
  margin-top: 25px !important;
}

/* TextField Styles */
.edit-profile-input {
  width: 100% !important;
  border-radius: 28px !important;
  opacity: 0.6 !important;
  border: 1px solid #362B24 !important;
}

.edit-profile-input .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 28px !important;
}

/* Save Button Container */
.save-button-container {
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

/* Custom Button Styles */
.custom-button-save {
  width: 337px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 28px !important; /* Ensure input fields have the border radius */
}


/* Override the default styles of the MUI dropdown */



/* changepass */

/* Label Typography Styles */
.change-password-label {
  margin-bottom: 8px !important;
  text-align: left !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: 'Heebo' !important;
  text-indent: 18px !important;
  margin-top: 15px !important;
}

/* TextField Styles */
.change-password-input {
  width: 100% !important;
  border-radius: 28px !important;
  opacity: 0.6 !important;
  border: 1px solid #362B24 !important;
}

.change-password-input .MuiOutlinedInput-root {
  border-radius: 25px !important;
}

.change-password-input .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* Password Toggle Icon */
.toggle-password-icon {
  width: 20px !important;
  cursor: pointer !important;
  opacity: 0.6 !important;
}
