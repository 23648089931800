.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 28px !important; 
  
  }

  .ql-container {
    /* border-radius: 8px;  */
    border-radius: 5px 5px 8px 8px;
}

.ql-editor {
  border-radius: 5px 5px 8px 8px;
    height: 120px; 
    padding: 10px; 
    box-sizing: border-box;
}
.ql-toolbar.ql-snow{
  border-radius: 8px;
}


/* modal1 */

.createNotificationModal1 {
  width: 90% !important;
  max-width: 640px !important;
  max-height: 80vh !important;
  background: #FFF !important;
  border-radius: 24px !important;
  padding: 24px !important;
  box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.04) !important;
  overflow-y: auto !important;
}

.createNotificationModal1::-webkit-scrollbar {
  display: none !important;
}

.notificationModal1Header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.notificationModal1Title {
  font-size: 20px !important;
  color: #362B24 !important;
  font-weight: 700 !important;
}


.notificationModal1FormContainer {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
  margin-top: 24px !important;
}

.notificationModal1FieldsContainer {
  display: flex !important;
  flex-direction: row !important;
  gap: 16px !important;
  flex-wrap: wrap !important;
}

.notificationModal1Field {
  flex: 1 !important;
}

.notificationModal1InputLabel {
  margin-bottom: 8px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #362B24 !important;
  padding-left: 15px !important;
}

.notificationModal1Select,
.notificationModal1DateInput,
.notificationModal1TimeInput {
  border-radius: 28px !important;
  border: 1.4px solid #362B24 !important;
  opacity: 0.6 !important;
  background-color: #FFF !important;
  width: 180px !important;
  height: 52px !important;
  flex-shrink: 0 !important;
  padding: 0 16px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
}

.notificationModal1DateInput {
  padding: 0 16px !important;
}

.notificationModal1TypeSelectionContainer {
  display: flex !important;
  justify-content: space-between !important;
  gap: 16px !important;
  align-items: center !important;
  margin-top: 24px !important;
  flex-wrap: wrap !important;
}

.notificationModal1TypeLabel {
  margin-bottom: 16px !important;
  font-weight: bold !important;
}

.notificationModal1RadioGroupContainer {
  display: flex !important;
  flex-direction: row !important;
  gap: 16px !important;
}

.notificationModal1Radio {
  & .MuiSvgIcon-root {
    font-size: 28px !important;
    color: transparent !important;
  }

  & .MuiRadio-root {
    position: relative !important;
  }

  &::before {
    content: "" !important;
    position: absolute !important;
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    border: 2px solid white !important;
    background-color: lightgrey !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  &.Mui-checked::before {
    background-color: green !important;
  }
}




.notificationModal1EmailEditor {
  height: 150px !important;
  margin-bottom: 60px !important;
  margin-top: 20px !important;
  border-radius: 18px !important;
}

.notificationModal1InputContainer {
  width: 100% !important;
  max-width: 624px !important;
  margin-top: 20px !important;
}

.notificationModal1InputLabel {
  margin-bottom: 8px !important;
  text-align: left !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: Heebo !important;
  color: #362B24 !important;
}

.notificationModal1Input {
  width: 100% !important;
  padding: 12px !important;
  border-radius: 28px !important;
  border: 1.4px solid #362B24 !important;
  background-color: #FFF !important;
  outline: none !important;
  box-sizing: border-box !important;
  opacity: 0.6 !important;
}

.notificationModal1Textarea {
  width: 100% !important;
  padding: 12px !important;
  height: 147px !important;
  border-radius: 28px !important;
  border: 1.4px solid #362B24 !important;
  background-color: #FFF !important;
  box-sizing: border-box !important;
  resize: none !important;
  outline: none !important;
  opacity: 0.6 !important;
}

.notificationModal1RadioContainer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 16px !important;
  margin-top: 24px !important;
  flex-wrap: wrap !important;
}

.notificationModal1RadioLabel {
  font-weight: bold !important;
}

.notificationModal1RadioGroup {
  display: flex !important;
  flex-direction: row !important;
  gap: 16px !important;
}

.notificationModal1RadioButton {
  & .MuiSvgIcon-root {
    font-size: 28px !important;
    color: transparent !important;
  }

  & .MuiRadio-root {
    position: relative !important;
  }

  &::before {
    content: "" !important;
    position: absolute !important;
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    border: 2px solid white !important;
    background-color: lightgrey !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  &.Mui-checked::before {
    background-color: green !important;
  }
}


.notificationModal1UserBox {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  gap: 8px !important;
  margin-top: 16px !important;
}

.notificationModal1UserDetails {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
}

.notificationModal1AvatarGroup {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

.notificationModal1Avatar {
  width: 25px !important;
  height: 25px !important;
}

.notificationModal1AttendanceText {
  font-weight: 400 !important;
  color: #362B24 !important;
  font-family: Heebo !important;
  font-size: 18px !important;
}

.notificationModal1SelectUserButton {
  margin-top: 16px !important;
  width: 145px !important;
  height: 52px !important;
  flex-shrink: 0 !important;
  border-radius: 28px !important;
  background: linear-gradient(180deg, #065F46 0%, #0D7B5C 100%) !important;
  box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important;
  color: white !important;
  text-transform: none !important;
}

.notificationModal1SelectUserButton:hover {
  opacity: 0.8 !important;
}

.notificationModal1VendorBox {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  gap: 8px !important;
  margin-top: 16px !important;
}

.notificationModal1VendorDetails {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
}

.notificationModal1AvatarGroup {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

.notificationModal1Avatar {
  width: 25px !important;
  height: 25px !important;
}

.notificationModal1VendorsText {
  font-weight: 400 !important;
  color: #362B24 !important;
  font-family: Heebo !important;
  font-size: 18px !important;
}

.notificationModal1SelectVendorButton {
  margin-top: 16px !important;
  width: 145px !important;
  height: 52px !important;
  flex-shrink: 0 !important;
  border-radius: 28px !important;
  background: linear-gradient(180deg, #065F46 0%, #0D7B5C 100%) !important;
  box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important;
  color: white !important;
  text-transform: none !important;
}

.notificationModal1SelectVendorButton:hover {
  opacity: 0.8 !important;
}




.notificationModal1ButtonContainer {
  display: flex !important;
  justify-content: center !important;
  gap: 16px !important;
  margin-top: 32px !important;
}

.notificationModal1SaveButton {
  border-radius: 29px !important;
  background: linear-gradient(180deg, #065F46 0%, #0D7B5C 100%) !important;
  max-width: 323px !important;
  height: 57px !important;
  width: 100% !important;
  align-self: center !important;
  margin-top: auto !important;
  text-transform: none !important;
  font-family: Heebo !important;
  box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06) !important;
}

.notificationModal1SaveButton:hover {
  opacity: 0.8 !important;
}





/* modal2 */

/* ModalStyles.css */

.secondModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondModal-box {
  width: 90%;
  max-width: 645px;
  max-height: 80vh;
  background-color: #FFF;
  border-radius: 32px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  padding: 24px;
  overflow-y: auto;
  flex-shrink: 0;
}

.secondModal-box::-webkit-scrollbar {
  display: none;
}

.secondModal-title {
  font-size: 20px;
  color: #362B24;
  font-weight: 700;
}

.secondModal-closeButton img {
  cursor: pointer;
}

.secondModal-search {
  width: 100%;
  height: 58px;
  border-radius: 33px;
  background: #EBEBEB;
  border: none;
  padding-left: 25px;
  padding-right: 10px;
}

.secondModal-searchInput .MuiOutlinedInput-root {
  border-radius: 33px;
  height: 100%;
  padding-left: 25px;
  padding-right: 10px;
  outline: none;
  border: none;
}

.secondModal-searchInput .MuiOutlinedInput-notchedOutline {
  border: none;
}

.secondModal-userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: #362B24;
  font-weight: 500;
}

.secondModal-selectButton,
.secondModal-rejectButton,
.secondModal-addButton {
  border-radius: 29px;
  max-width: 94px;
  height: 41px;
  width: 100%;
  align-self: center;
  text-transform: none !important;
  font-family: Heebo;
  box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
  border-radius: 28px !important;
}

.secondModal-selectButton {
  background: linear-gradient(180deg, #065F46 0%, #0D7B5C 100%);
}

.secondModal-rejectButton {
  background: linear-gradient(180deg, #EA001B 0%, #EA001B 100%);
}

.secondModal-addButton {
  background: linear-gradient(180deg, #065F46 0%, #0D7B5C 100%);
  max-width: 358px;
  height: 59px;
}
