@media (max-width: 480px) {
    .responsive-input {
      width: 40px !important;
      height: 40px !important;
      font-size: 1.2rem !important;
      overflow-y: none !important;
    }
  }

  

  