#sidebar {
    background-color: transparent;
    /* border-radius: 0px 40px 40px 0px; */
    background-image: url('../assests//sidebarImg.png');
    background-size: cover;
    transform-origin: top left;
}

/* .sidebar-list { */
    /* margin-top: 204px; */
/* } */

.sidebar-list-dash,
.sidebar-list-user, 
.sidebar-list-vendor, 
.sidebar-list-orders, 
.sidebar-list-content-moderation, 
.sidebar-list-report-analysis, 
.sidebar-list-event-management, 
.sidebar-list-push-notification, 
.sidebar-list-help-support {
    margin-top: 33px;
    margin-left: 35px !important;
}

.sidebar-responsive {
    position: fixed; 
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; 
    width: 317px;
    overflow-y: auto; 
}
.sidebar-responsive {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    width: 317px;
    transition: transform 0.3s ease; 
  }
  
  

  @media (max-width: 1024px) {
   
     .sidebar-responsive {
        /* position: absolute; */
        height: 100%; 
        width: 250px; 
        top: 0;
        left: 0;
        z-index: 2000;
    }

  }
  









.crossicon {
    position: absolute;
    left: 241px;
    top: 7px;
    background-color: white;
    width: 20px;
    height: 26px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
}

@media screen and (min-width: 992px) {
    .crossicon {
        display: none;
    }
}

@media screen and (min-width:1600px) {
    .sidebar-list{
        margin-top: 80px;
    }
}

.reyleaf-img {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dash-text,
.user-text,
.vendor-text,
.orders-text,
.content-moderation-text,
.report-analysis-text,
.event-management-text,
.push-notification-text,
.help-support-text {
    display: inline-block;
    position: absolute; 
    margin-left: 30px;
    color: #fff;
    font-family: 'Heebo';
    font-size: 16px;
    font-weight: 100;
    font-style: normal;
    text-indent: 10px;
}


.dash-icon,
.user-icon,
.vendor-icon,
.orders-icon,
.content-moderation-icon,
.report-analysis-icon,
.event-management-icon,
.push-notification-icon,
.help-support-icon {
    width: 27px;
    height: 27px;
    position: absolute;
    color: #FFF;
}

.anch-icon {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #ffffff;
    width: 60%;
}

.anch-icon img {
    margin-right: 10px;
}

.bold {
    font-weight: bold;
}

.sidebar-list-item {
    position: relative;
    padding-left: 8px;
}

/* .sidebar-list-item.active::before {
   
} */

.active .dash-text,
.active .user-text,
.active .vendor-text,
.active .orders-text,
.active .content-moderation-text,
.active .report-analysis-text,
.active .event-management-text,
.active .push-notification-text,
.active .help-support-text {
    font-weight: bold;
    color: white;
}


.icon-txt-combine{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}




:root {
    --icon-default-filter: invert(0%) sepia(0%) saturate(100%) brightness(100%) contrast(100%);
    --icon-active-filter: invert(100%) sepia(0%) saturate(100%) brightness(100%) contrast(100%);
  }
  
  .icon {
    width: 24px; /* Adjust size as needed */
    height: 24px;
    filter: var(--icon-default-filter); /* Default filter */
    transition: filter 0.3s ease; /* Smooth transition */
  }
  
  .icon.active {
    
    background-color: var(--icon-active-filter) ;
    color:  var(--icon-active-filter);
  }
  
/* 

  .unique-frame {
    border: 2px solid black;
    height: 500px;
    width: 800px;
    margin: 2rem auto;
    position: relative;
  }

  .angled-top-corner {
    position: absolute;
    top: 1px;
    left: 0;
    width: 0;
    height: 0;
    bottom: 0;
    border-right: 176px solid transparent;
    border-bottom: 30px solid green;
    border-bottom-right-radius: 10px;
  }

  .angled-bottom-corner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-right: 176px solid transparent;
    border-top: 30px solid green;
    border-top-right-radius: 10px;
  }

  .vertical-green-bar {
    position: absolute;
    top: 30px;
    left: 0;
    height: calc(100% - 60px);
    width: 188px;
    background-color: green;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .vertical-green-bar h1{
    color: white;
    text-align: center;
  }











 */
