
 /* For screen sizes 1600px and above */
 @media  screen and (min-width: 1600px) {
    .custom-row > .col-12 {
      flex: none !important;
    }
  }
  
  /* For screen sizes between 1401px and 1599px */
  @media  screen and (min-width: 1401px) and (max-width: 1599px) {
    .custom-row-1 > .col-12 {
        flex: 0 25%;
    }
  }
  
  /* For screen sizes between 1200px and 1400px */
  @media  screen and (min-width: 1200px) and (max-width: 1400px) {
    .custom-row-1 > .col-12 {
      flex: 0 0 30%; /* 3 cards per row */
    }
  }
  
  /* For screen sizes between 1024px and 1199px */
  @media  screen and (min-width: 1024px) and (max-width: 1199px) {
    .custom-row-1 > .col-12 {
      flex: 0 0 48%; /* 2 cards per row */
    }
  }
  
  /* For screen sizes 1023px and below */
  @media screen and (max-width: 1023px) {
    .custom-row-1 > .col-12 {
      flex: 0 0  30%; 
    }
  }

  @media  screen and (max-width: 767px) {
    .custom-row-1 > .col-12 {
      flex: 0 0 31%; 
    }
  }

  @media  screen and (max-width: 576px) {
    .custom-row-1 > .col-12 {
      flex: 1 50%; 
    }
  }
  
  @media  screen and (max-width: 480px) {
    .custom-row-1> .col-12 {
      flex: 1 1 100%; 
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
    }
  }
  




  .graph-container-width{
    max-width: 717px;
    width: 100%;
}@media screen and (max-width:1599px) {
    .graph-container-width{
         max-width: 540px;
         /* width: 100%; */
         /* width:  */
     }
     
 }
 
 .custom-button-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 352px;
  /* width: 100%; */
  /* max-width: 852px; */
  height: 66px;
  border-radius: 34px;
  background: #FFF;
  box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
  font-weight: bold;
  font-family: 'Heebo';
  box-sizing: border-box;
}

.custom-toggle-btn {
  padding: 10px 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
  color: #362B24;
  opacity: 0.4;
  font-family: 'Heebo';
  font-size: 14px;
  width: 50%; 
}

#custom-btn {
  position: absolute;
  height: 54px;
  border-radius: 28px;
  background: #065F46;
  transition: all 0.3s;
  z-index: 0;
  margin-left: 5px;
}

/* Active state styling for buttons */
.custom-toggle-btn.active {
  color: #FFF;
  opacity: 1;
  font-weight: 700;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .custom-button-box {
    height: 50px;
    border-radius: 25px;
    width: 220px;
  }
  
  .custom-toggle-btn {
    padding: 10px 16px;
    font-size: 10px;
  }

  #custom-btn {
    height: 40px;
    width: 44% !important;
    border-radius: 20px;
  }
}
