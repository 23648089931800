.help-support-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .help-support-button-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 534px;
    height: 66px;
    border-radius: 34px;
    background: #FFF;
    box-shadow: 1px 2px 30px 0px rgba(0, 0, 0, 0.06);
    font-weight: bold;
    font-family: 'Heebo';
    box-sizing: border-box;
  }
  
  .help-support-toggle-btn {
    padding: 10px 20px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1; */
    color: #362B24;
    opacity: 0.4;
    font-family: 'Heebo';
    font-size: 14px;
    width: 33.33%;
  }
  
  #help-support-btn {
    position: absolute;
    height: 54px;
    border-radius: 28px;
    background: #065F46;
    transition: all 0.3s;
    z-index: 0;
    margin-left: 5px;
  }
  
  .help-support-toggle-btn.active {
    color: #FFF;
    opacity: 1;
    font-weight: 700;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .help-support-button-box {
      height: 50px;
      border-radius: 25px;
      width: 286px;
    }
  
    .help-support-toggle-btn {
      padding: 10px 16px;
      font-size: 8px;
    }
  
    #help-support-btn {
      height: 40px;
      width: 34% !important;
      border-radius: 20px;
    }
  }
  


  .modal-texts{
   font-size:20px  !important;
   color:#362B24 !important;
   font-weight:700 !important;
  }

  .modal-texts-1{
    font-size:13px  !important;
    color:#362B24 !important;
    font-weight:400 !important;
    opacity: 0.55 !important;
  }

  /* @media screen and (max-width:1200px) {
    .sendIcon-design{
        height:150px;
        width: 90px;
    }
  } */
 